import React from 'react';
import PropTypes from 'prop-types';

class FacetRow extends React.Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    this.props.onClick(this.props.type);
  }

  render() {
    return (
      <a
        className="Search-facetLink"
        href="#"
        onClick={this.onClick}
      >{this.props.name} ({this.props.count})
      </a>
    );
  }
}

FacetRow.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};

FacetRow.defaultProps = {
  type: null,
};

export default FacetRow;
