/* global $, ga */
import React from 'react';
import debounce from 'lodash/debounce';

import FacetRow from './FacetRow';


const getHighlightedItem = (items) => {
  if (items.length === 0) return null;
  if (items.length === 1) return items[0];

  for (let i = 0; i < items.length - 1; i++) {
    if (items[i].indexOf('<b>') !== -1) return items[i];
  }
  return items.slice(-1)[0];
};


class SearchComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      searchTerm: '',
      type: null,
      pageNumber: 1,
      list: [],
      total: null,
      facets: null,
      isLoading: false,
    };
    this.modalRef = React.createRef();
    this.modalBodyRef = React.createRef();
    this.inputRef = React.createRef();
    this.onEscKeyDown = this.onEscKeyDown.bind(this);
    this.onShow = this.onShow.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSearch = debounce(this.onSearch.bind(this), 500);
    this.getSearchResult = this.getSearchResult.bind(this);
    this.setSearchType = this.setSearchType.bind(this);
    this.loadMore = this.loadMore.bind(this);

    this.searchApiUrl = (
      window.siteSettings && window.siteSettings.searchApiUrl
    ) || '/api/search/v1/';
  }

  componentDidMount() {
    // button on header
    $('#header-search > button').on('click', () => { this.onShow(); });
    // invoke the search form using a custom form on the page
    $('form[data-search-type]').on('submit', (event) => {
      event.preventDefault();
      const searchType = event.target.dataset.searchType || null;

      let searchTerm = '';
      // not using FormData because IE11
      for (let i = 0; i < event.target.elements.length; i++) {
        if (event.target.elements[i].name === 'q') {
          searchTerm = event.target.elements[i].value;
          break;
        }
      }
      this.onSearch({ searchTerm, type: searchType, pageNumber: 1 });
      this.onShow(() => { this.inputRef.current.value = searchTerm; });
    });
  }

  onChange(e) {
    const searchTerm = e.target.value || '';
    this.onSearch({ searchTerm, type: null, pageNumber: 1 });
  }

  onEscKeyDown(evt) {
    const event = evt || window.event;
    let isEscape = false;
    if ('key' in event) {
      isEscape = (event.key === 'Escape' || event.key === 'Esc');
    } else {
      isEscape = (event.keyCode === 27);
    }
    if (!isEscape) return;
    this.onClose();
  }

  onShow(callback) {
    this.setState({ show: true }, () => {
      document.addEventListener('keydown', this.onEscKeyDown);
      $(this.modalRef.current).modal('show');
      if (callback) callback();
    });
  }

  onClose() {
    this.setState({ show: false }, () => {
      document.removeEventListener('keydown', this.onEscKeyDown);
      $(this.modalRef.current).modal('hide');
    });
  }

  onSearch(newState) {
    const append = newState.append || false;
    if (!append && newState.searchTerm && window.ga && window.ga.getAll) {
      const query = newState.searchTerm;
      const tracker = ga.getAll()[0];
      if (tracker && query) {
        tracker.send(
          'pageview',
          `/search-results/?query=${query.toLowerCase()}`,
        );
      }
    }
    this.setState({ ...newState, isLoading: true }, () => {
      $.ajax({
        url: this.searchApiUrl,
        data: {
          q: this.state.searchTerm,
          type: this.state.type,
          page: this.state.pageNumber,
        },
        timeout: 30000,
      }).then((data) => {
        const updatedState = {
          list: append ?
            [].concat(this.state.list, data.objects) : data.objects,
          currentPage: data.page,
          isLoading: false,
        };
        if (!append) {
          Object.assign(updatedState, {
            total: data.total,
            facets: data.facets,
          });
        }
        this.setState(updatedState);
      }).catch(() => {
        this.setState({ isLoading: false });
      });
    });
  }

  getSearchResult() {
    if (this.state.total === null) {
      return null;
    }
    if (this.state.total === 0) {
      return <div className="Search-resultsCount">No results</div>;
    }
    let facets = null;
    if (this.state.facets) {
      facets = (
        <ul className="Search-facets">
          {this.state.facets.map((facet) => (
            <li
              key={facet.type}
              className={`Search-facetRow ${
                (this.state.type === facet.type) ?
                  'Search-facetRow--selected' : ''
              }`}
            >
              <FacetRow
                type={facet.type}
                name={facet.name}
                count={facet.count}
                onClick={this.setSearchType}
              />
            </li>
          ))}
        </ul>
      );
    }
    const rows = this.state.list.map((item) => (
      <div key={item.id} className="Search-resultRow">
        <div className="Heading Heading--s Heading--compact">
          <a
            href={item.url}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: getHighlightedItem([
                item.highlight_headline,
                item.headline,
              ]),
            }}
          />
        </div>
        <div
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: getHighlightedItem([
              item.highlight_subhead,
              item.highlight_text,
              item.subhead,
            ]),
          }}
        />
      </div>
    ));
    return (
      <>
        <div className="Search-resultsCount">
          {this.state.total} result(s) found
        </div>
        {facets}
        {rows}
      </>
    );
  }

  setSearchType(type) {
    this.onSearch({ type, pageNumber: 1 });
  }

  loadMore() {
    this.onSearch({ pageNumber: this.state.pageNumber + 1, append: true });
  }

  render() {
    return (
      <div
        ref={this.modalRef}
        className={`Modal Modal--L Modal--closeOutside Modal--fullHeight ${
          (this.state.isLoading && this.state.pageNumber === 1 ?
            'is-processing' : ''
          )
        } fade ${
          (this.state.show ? 'in' : '')
        }`}
        data-backdrop="static"
        tabIndex="-1"
        style={{ display: this.state.show ? 'block' : 'none' }}
      >
        <div className="Modal-dialog">
          <p className="Modal-title Modal-title--wInput">
            <input
              className="Modal-titleInput"
              type="text"
              ref={this.inputRef}
              onChange={this.onChange}
              placeholder="Please enter a search term"
            />
            <a // eslint-disable-line jsx-a11y/anchor-has-content
              className="Modal-titleIcon"
              href="#"
              onClick={this.onClose}
            />
          </p>
          <div ref={this.modalBodyRef} className="Modal-body">
            <div className="Search">
              {this.getSearchResult()}
              {this.state.total && this.state.total > this.state.list.length ? (
                <div className="Search-loadMore">
                  <a
                    className={`Button Button--l ${(
                      this.state.isLoading && this.state.pageNumber > 1 ?
                        'is-processing' : ''
                    )}`}
                    onClick={this.loadMore}
                    role="button"
                    tabIndex="0"
                  >
                    <span className="Button-icon">
                      <i className="Icon Icon--arrows-cw" />
                    </span>
                    Show More
                  </a>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SearchComponent;
