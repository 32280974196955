import React from 'react';
import ReactDOM from 'react-dom';

import ErrorBoundary from '../ErrorBoundary';
import SearchComponent from './SearchComponent';

window.addEventListener('DOMContentLoaded', () => {
  const topSearchButton = document.getElementById('header-search');
  if (!topSearchButton) return;

  const searchRoot = document.createElement('div');
  document.body.appendChild(searchRoot);

  ReactDOM.render(
    <ErrorBoundary>
      <SearchComponent />
    </ErrorBoundary>,
    searchRoot,
  );
});
